import {lazy} from "@loadable/component";
import IRoutes from "../types/IRoutes";

const View = lazy(
  () => import(/* webpackChunkName: 'analytics-view' */ "./View")
);

const routes: IRoutes = [
  {
    component: View,
    name: "Statistiche",
    path: "/statistiche/:id?",
    permission: "stats.read",
  },
];

export default routes;
