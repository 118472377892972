import {batch} from "react-redux";
import {Dispatch} from "redux";
import {SubmissionError} from "redux-form";
import {Actions as CapsAction} from "../Caps/actions";
import {IUploadIdFormData} from "../Caps/NewPage/ClosingSection/IdentificationDrawer/UploadIdForm";
import {IRootState} from "../redux/reducers";
import IApiError from "../types/IApiError";
import {actions as usersActions} from "../Users/actions";
import {ICourse, IPrivacySnapshot, IUser, IUserEntity} from "../Users/types";
import {IActivateFormData} from "./ActivateForm";
import {IActivateWithTokenFormData} from "./ActivateWithTokenForm";
import {ILoginFormData} from "./LoginForm";
import {ILostPasswordFormData} from "./LostPasswordForm";
import {IPrivacyFormData} from "./PrivacyForm";
import {IPrivacySubscriptionFormData} from "./PrivacySubscriptionForm";
import userService from "./service";
import {
  ICompaniesName,
  ILoggedUserLocalstorage,
  IPermissionName,
  IPrivacy,
  IPrivacyQuestion,
  IPrivacySections,
} from "./types";

export enum actionTypes {
  PING_REQUEST = "[User] ping request",
  PING_SUCCESS = "[User] ping success",
  PING_FAIL = "[User] ping fail",

  UPDATE_LOGGED_USER = "[User] Update logged user",

  PROFILE_REQUEST = "[User] profile request",
  PROFILE_SUCCESS = "[User] profile success",
  PROFILE_FAIL = "[User] profile fail",

  LOGIN_REQUEST = "[User] login request",
  LOGIN_SUCCESS = "[User] login success",
  LOGIN_FAIL = "[User] login fail",

  LOGOUT_REQUEST = "[User] logout request",
  LOGOUT_SUCCESS = "[User] logout success",
  LOGOUT_FAIL = "[User] logout fail",

  LOAD_PRIVACY_REQUEST = "[User] load privacy from db request",
  LOAD_PRIVACY_SUCCESS = "[User] load privacy from db success",
  LOAD_PRIVACY_FAIL = "[User] load privacy from db fail",

  PRIVACY_OPEN = "[User] Open privacy modal",
  PRIVACY_REQUEST = "[User] privacy request",
  PRIVACY_SUCCESS = "[User] privacy success",
  PRIVACY_FAIL = "[User] privacy fail",

  PRIVACY_SUBSCRIPTION_REQUEST = "[User] privacy subscription request",
  PRIVACY_SUBSCRIPTION_SUCCESS = "[User] privacy subscription success",
  PRIVACY_SUBSCRIPTION_FAIL = "[User] privacy subscription fail",

  UPLOAD_ID_REQUEST = "[User] Upload identification document request",
  UPLOAD_ID_SUCCESS = "[User] Upload identification document success",
  UPLOAD_ID_FAIL = "[Users] Upload identification document fail",

  ACTIVATE_REQUEST = "[User] activate request",
  ACTIVATE_SUCCESS = "[User] activate success",
  ACTIVATE_FAIL = "[User] activate fail",

  ACTIVATE_WITH_TOKEN_REQUEST = "[User] activate with token request",
  ACTIVATE_WITH_TOKEN_SUCCESS = "[User] activate with token success",
  ACTIVATE_WITH_TOKEN_FAIL = "[User] activate with token fail",

  LOST_PASSWORD_WITH_TOKEN_REQUEST = "[User] lost password with token request",
  LOST_PASSWORD_WITH_TOKEN_SUCCESS = "[User] lost password with token success",
  LOST_PASSWORD_WITH_TOKEN_FAIL = "[User] lost password with token fail",

  LOST_PASSWORD_REQUEST = "[User] lost password request",
  LOST_PASSWORD_SUCCESS = "[User] lost password success",
  LOST_PASSWORD_FAIL = "[User] lost password fail",

  TOGGLE_APP_ALERT = "",
}

interface IPingRequestAction {
  type: typeof actionTypes.PING_REQUEST;
}
interface IPingSuccessAction {
  type: typeof actionTypes.PING_SUCCESS;
}
interface IPingFailAction {
  type: typeof actionTypes.PING_FAIL;
  payload: IApiError;
}

interface IUpdateUserLocalstorageAction {
  type: typeof actionTypes.UPDATE_LOGGED_USER;
  payload: {
    user?: IUser | null;
    permissions?: IPermissionName[];
    userCompanies?: ICompaniesName[];
    coursesHistory?: ICourse[];
  };
}

interface IProfileRequestAction {
  type: typeof actionTypes.PROFILE_REQUEST;
}
interface IProfileSuccessAction {
  type: typeof actionTypes.PROFILE_SUCCESS;
}
interface IProfileFailAction {
  type: typeof actionTypes.PROFILE_FAIL;
  payload: IApiError;
}

interface ILoginRequestAction {
  type: typeof actionTypes.LOGIN_REQUEST;
}
interface ILoginSuccessAction {
  type: typeof actionTypes.LOGIN_SUCCESS;
}
interface ILoginFailAction {
  type: typeof actionTypes.LOGIN_FAIL;
  payload: IApiError;
}

interface ILogoutRequestAction {
  type: typeof actionTypes.LOGOUT_REQUEST;
}
interface ILogoutSuccessAction {
  type: typeof actionTypes.LOGOUT_SUCCESS;
}
interface ILogoutFailAction {
  type: typeof actionTypes.LOGOUT_FAIL;
  payload: IApiError;
}

interface ILoadPrivacyRequestAction {
  type: typeof actionTypes.LOAD_PRIVACY_REQUEST;
}
interface ILoadPrivacySuccessAction {
  type: typeof actionTypes.LOAD_PRIVACY_SUCCESS;
  payload: {privacy: IPrivacy; privacySubscription: IPrivacy};
}
interface ILoadPrivacyFailAction {
  type: typeof actionTypes.LOAD_PRIVACY_FAIL;
  payload: IApiError;
}

interface IActivateRequestAction {
  type: typeof actionTypes.ACTIVATE_REQUEST;
}
interface IActivateSuccessAction {
  type: typeof actionTypes.ACTIVATE_SUCCESS;
  payload: string;
}
interface IActivateFailAction {
  type: typeof actionTypes.ACTIVATE_FAIL;
  payload: IApiError;
}

interface IActivateWithTokenRequestAction {
  type: typeof actionTypes.ACTIVATE_WITH_TOKEN_REQUEST;
}
interface IActivateWithTokenSuccessAction {
  type: typeof actionTypes.ACTIVATE_WITH_TOKEN_SUCCESS;
}
interface IActivateWithTokenFailAction {
  type: typeof actionTypes.ACTIVATE_WITH_TOKEN_FAIL;
  payload: IApiError;
}

interface IPrivacyOpenModalAction {
  type: typeof actionTypes.PRIVACY_OPEN;
}
interface IPrivacyRequestAction {
  type: typeof actionTypes.PRIVACY_REQUEST;
}
interface IPrivacySuccessAction {
  type: typeof actionTypes.PRIVACY_SUCCESS;
}
interface IPrivacyFailAction {
  type: typeof actionTypes.PRIVACY_FAIL;
  payload: {
    error: IApiError;
  };
}

interface IPrivacySubscriptionRequestAction {
  type: typeof actionTypes.PRIVACY_SUBSCRIPTION_REQUEST;
}
interface IPrivacySubscriptionSuccessAction {
  type: typeof actionTypes.PRIVACY_SUBSCRIPTION_SUCCESS;
}
interface IPrivacySubscriptionFailAction {
  type: typeof actionTypes.PRIVACY_SUBSCRIPTION_FAIL;
  payload: {
    error: IApiError;
  };
}

interface IUploadIdRequestAction {
  type: typeof actionTypes.UPLOAD_ID_REQUEST;
}
interface IUploadIdSuccessAction {
  type: typeof actionTypes.UPLOAD_ID_SUCCESS;
}
interface IUploadIdFailAction {
  type: typeof actionTypes.UPLOAD_ID_FAIL;
  payload: {
    error: IApiError;
  };
}

interface ILostPasswordWithTokenRequestAction {
  type: typeof actionTypes.LOST_PASSWORD_WITH_TOKEN_REQUEST;
}
interface ILostPasswordWithTokenSuccessAction {
  type: typeof actionTypes.LOST_PASSWORD_WITH_TOKEN_SUCCESS;
}
interface ILostPasswordWithTokenFailAction {
  type: typeof actionTypes.LOST_PASSWORD_WITH_TOKEN_FAIL;
  payload: IApiError;
}

interface ILostPasswordRequestAction {
  type: typeof actionTypes.LOST_PASSWORD_REQUEST;
}
interface ILostPasswordSuccessAction {
  type: typeof actionTypes.LOST_PASSWORD_SUCCESS;
  payload: string;
}
interface ILostPasswordFailAction {
  type: typeof actionTypes.LOST_PASSWORD_FAIL;
  payload: IApiError;
}

interface IToggleAppAlertAction {
  type: typeof actionTypes.TOGGLE_APP_ALERT;
}

export const actions = {
  ping: () => (dispatch: Dispatch<actions>) => {
    dispatch(actions.pingRequest());
    return userService
      .ping()
      .then((data) => {
        batch(() => {
          dispatch(actions.pingSuccess());
          dispatch(actions.updateUserLocalStorage(data));
        });
      })
      .catch((error) => {
        dispatch(actions.pingFail(error));
      });
  },
  pingFail: (error: IApiError): IPingFailAction => ({
    payload: error,
    type: actionTypes.PING_FAIL,
  }),
  pingRequest: (): IPingRequestAction => ({
    type: actionTypes.PING_REQUEST,
  }),
  pingSuccess: (): IPingSuccessAction => ({
    type: actionTypes.PING_SUCCESS,
  }),

  updateUserLocalStorage: (data: {
    user?: IUser | null;
    permissions?: IPermissionName[];
    userCompanies?: ICompaniesName[];
    coursesHistory?: ICourse[];
  }): IUpdateUserLocalstorageAction => {
    const key = `${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_LOCALSTORAGE_USER_KEY}`;
    const userStorage = JSON.parse(
      localStorage.getItem(key)!
    ) as ILoggedUserLocalstorage;

    const newData: ILoggedUserLocalstorage = {
      permissions:
        data.permissions === undefined
          ? userStorage.permissions
          : data.permissions,
      userCompanies:
        data.userCompanies === undefined
          ? userStorage.userCompanies
          : data.userCompanies,
      user: data.user === undefined ? userStorage.user : data.user,
      coursesHistory:
        data.coursesHistory === undefined
          ? userStorage.coursesHistory
          : data.coursesHistory,
    };

    localStorage.setItem(key, JSON.stringify(newData));

    return {
      payload: data,
      type: actionTypes.UPDATE_LOGGED_USER,
    };
  },

  profile:
    (values: Partial<IUserEntity>) =>
    (dispatch: Dispatch<actions>, getState: () => IRootState) => {
      dispatch(actions.profileRequest());
      const user = getState().user.user;
      return userService
        .profile(values)
        .then((responseJson) => {
          if (
            Object.keys(values).includes("usid") &&
            values.usid !== user!.usid
          ) {
            // Se si è modificato lo usid effettuiamo il logout
            actions.logout()(dispatch);
          } else {
            // Altrimenti aggiorniamo il localstorage
            batch(() => {
              dispatch(
                actions.updateUserLocalStorage({user: responseJson.user})
              );
              dispatch(actions.profileSuccess());
            });
          }
        })
        .catch((error) => {
          dispatch(actions.profileFail(error));
        });
    },
  profileFail: (error: IApiError): IProfileFailAction => ({
    payload: error,
    type: actionTypes.PROFILE_FAIL,
  }),
  profileRequest: (): IProfileRequestAction => ({
    type: actionTypes.PROFILE_REQUEST,
  }),
  profileSuccess: (): IProfileSuccessAction => ({
    type: actionTypes.PROFILE_SUCCESS,
  }),

  login: (values: ILoginFormData) => (dispatch: Dispatch<actions>) => {
    dispatch(actions.loginRequest());
    return userService
      .login(values)
      .then(({user, permissions, userCompanies, coursesHistory}) => {
        batch(() => {
          dispatch(
            actions.updateUserLocalStorage({
              permissions,
              user,
              userCompanies,
              coursesHistory,
            })
          );
          dispatch(actions.loginSuccess());
        });
        return {status: "ok"};
      })
      .catch((error) => {
        if (error.code && error.code === 901) {
          // Sono già loggato
          batch(() => {
            dispatch(
              actions.updateUserLocalStorage({
                permissions: error.permissions,
                userCompanies: error.userCompanies,
                user: error.user,
                coursesHistory: error.coursesHistory,
              })
            );
            dispatch(actions.loginSuccess());
          });
        } else {
          batch(() => {
            actions.updateUserLocalStorage({
              permissions: [],
              userCompanies: [],
              user: null,
              coursesHistory: [],
            });
            dispatch(actions.loginFail(error));
          });
        }
      });
  },
  loginFail: (error: IApiError): ILoginFailAction => ({
    payload: error,
    type: actionTypes.LOGIN_FAIL,
  }),
  loginRequest: (): ILoginRequestAction => ({
    type: actionTypes.LOGIN_REQUEST,
  }),
  loginSuccess: (): ILoginSuccessAction => {
    return {
      type: actionTypes.LOGIN_SUCCESS,
    };
  },

  logout: () => (dispatch: Dispatch<actions>) => {
    dispatch(actions.logoutRequest());
    return userService
      .logout()
      .then(() => {
        batch(() => {
          dispatch(
            actions.updateUserLocalStorage({
              user: null,
              permissions: [],
              userCompanies: [],
              coursesHistory: [],
            })
          );
          dispatch(actions.logoutSuccess());
        });
      })
      .catch((error) => {
        if (error.code && error.code === 904) {
          // Sono già sloggato
          batch(() => {
            dispatch(
              actions.updateUserLocalStorage({
                user: null,
                permissions: [],
                userCompanies: [],
                coursesHistory: [],
              })
            );
            dispatch(actions.logoutSuccess());
          });
        } else {
          dispatch(actions.logoutFail(error));
        }
      });
  },
  logoutFail: (error: IApiError): ILogoutFailAction => ({
    payload: error,
    type: actionTypes.LOGOUT_FAIL,
  }),
  logoutRequest: (): ILogoutRequestAction => ({
    type: actionTypes.LOGOUT_REQUEST,
  }),
  logoutSuccess: (): ILogoutSuccessAction => {
    return {
      type: actionTypes.LOGOUT_SUCCESS,
    };
  },

  loadPrivacy: () => (dispatch: Dispatch<actions>) => {
    dispatch(actions.loadPrivacyRequest());
    return userService
      .loadPrivacy()
      .then(([privacy, privacySubscription]) => {
        dispatch(actions.loadPrivacySuccess(privacy, privacySubscription));
      })
      .catch((error) => {
        dispatch(actions.loadPrivacyFail(error));
      });
  },
  loadPrivacyFail: (error: IApiError): ILoadPrivacyFailAction => ({
    payload: error,
    type: actionTypes.LOAD_PRIVACY_FAIL,
  }),
  loadPrivacyRequest: (): ILoadPrivacyRequestAction => ({
    type: actionTypes.LOAD_PRIVACY_REQUEST,
  }),
  loadPrivacySuccess: (
    privacy: IPrivacy,
    privacySubscription: IPrivacy
  ): ILoadPrivacySuccessAction => {
    return {
      type: actionTypes.LOAD_PRIVACY_SUCCESS,
      payload: {privacy, privacySubscription},
    };
  },

  activate: (values: IActivateFormData) => (dispatch: Dispatch<actions>) => {
    dispatch(actions.activateRequest());
    return userService
      .activate(values)
      .then((responseJson) => {
        dispatch(actions.activateSuccess(responseJson.email));
      })
      .catch((error) => {
        console.error(error); // tslint:disable-line
        dispatch(actions.activateFail(error));
      });
  },
  activateFail: (error: IApiError): IActivateFailAction => ({
    payload: error,
    type: actionTypes.ACTIVATE_FAIL,
  }),
  activateRequest: (): IActivateRequestAction => ({
    type: actionTypes.ACTIVATE_REQUEST,
  }),
  activateSuccess: (email: string): IActivateSuccessAction => ({
    payload: email,
    type: actionTypes.ACTIVATE_SUCCESS,
  }),

  activateWithToken:
    (values: IActivateWithTokenFormData) => (dispatch: Dispatch<actions>) => {
      dispatch(actions.activateWithTokenRequest());
      return userService
        .activateWithToken(values)
        .then(() => {
          dispatch(actions.activateWithTokenSuccess());
        })
        .catch((error) => {
          if (error.code === 910 || error.code === 913 || error.code === 920) {
            dispatch(actions.activateWithTokenFail(error));
            throw new SubmissionError({
              token: error.message,
            });
          }
          console.error(error); // tslint:disable-line
          dispatch(actions.activateWithTokenFail(error));
        });
    },
  activateWithTokenFail: (error: IApiError): IActivateWithTokenFailAction => ({
    payload: error,
    type: actionTypes.ACTIVATE_WITH_TOKEN_FAIL,
  }),
  activateWithTokenRequest: (): IActivateWithTokenRequestAction => ({
    type: actionTypes.ACTIVATE_WITH_TOKEN_REQUEST,
  }),
  activateWithTokenSuccess: (): IActivateWithTokenSuccessAction => ({
    type: actionTypes.ACTIVATE_WITH_TOKEN_SUCCESS,
  }),

  privacy:
    (values: IPrivacyFormData) =>
    (
      dispatch: Dispatch<actions | usersActions>,
      getState: () => IRootState
    ) => {
      dispatch(actions.privacyRequest());
      const lastPrivacy = getState().user.privacy!;
      const lastPrivacySections = JSON.parse(
        lastPrivacy.json
      ) as IPrivacySections;
      const lastPrivacyQuestions = lastPrivacySections.reduce(
        (questions, section) => {
          return questions.concat(section.questions);
        },
        [] as IPrivacyQuestion[]
      );

      const newPrivacy: Partial<IPrivacySnapshot> = {
        version: lastPrivacy.version,
        values: lastPrivacyQuestions.reduce(
          (obj, question) => ({
            ...obj,
            [question.name]: !!values[question.name],
          }),
          {}
        ) as unknown as {[Key: string]: boolean},
      };

      const jsonPrivacy = JSON.stringify(newPrivacy);

      return userService
        .privacy({jsonPrivacy})
        .then(({user}) => {
          batch(() => {
            dispatch(actions.updateUserLocalStorage({user}));
            dispatch(actions.privacySuccess());
          });
        })
        .catch((error) => {
          dispatch(actions.privacyFail(error));
        });
    },
  privacyFail: (error: IApiError): IPrivacyFailAction => ({
    payload: {error},
    type: actionTypes.PRIVACY_FAIL,
  }),
  privacyOpenModal: (): IPrivacyOpenModalAction => ({
    type: actionTypes.PRIVACY_OPEN,
  }),
  privacyRequest: (): IPrivacyRequestAction => ({
    type: actionTypes.PRIVACY_REQUEST,
  }),
  privacySuccess: (): IPrivacySuccessAction => ({
    type: actionTypes.PRIVACY_SUCCESS,
  }),

  privacySubscription:
    (values: IPrivacySubscriptionFormData) =>
    (
      dispatch: Dispatch<actions | usersActions>,
      getState: () => IRootState
    ) => {
      dispatch(actions.privacySubscriptionRequest());
      const lastPrivacySubscription = getState().user.privacySubscription!;
      const lastPrivacySubscriptionSections = JSON.parse(
        lastPrivacySubscription.json
      ) as IPrivacySections;
      const lastPrivacySubscriptionQuestions =
        lastPrivacySubscriptionSections.reduce((questions, section) => {
          return questions.concat(section.questions);
        }, [] as IPrivacyQuestion[]);

      const newPrivacySubscription: Partial<IPrivacySnapshot> = {
        version: lastPrivacySubscription.version,
        values: lastPrivacySubscriptionQuestions.reduce(
          (obj, question) => ({
            ...obj,
            [question.name]: !!values[question.name],
          }),
          {}
        ) as unknown as {[Key: string]: boolean},
      };

      const jsonPrivacySubscription = JSON.stringify(newPrivacySubscription);

      return userService
        .privacySubscription({jsonPrivacySubscription})
        .then(({user}) => {
          batch(() => {
            dispatch(actions.updateUserLocalStorage({user}));
            dispatch(actions.privacySubscriptionSuccess());
          });
        })
        .catch((error) => {
          dispatch(actions.privacySubscriptionFail(error));
        });
    },
  privacySubscriptionFail: (
    error: IApiError
  ): IPrivacySubscriptionFailAction => ({
    payload: {error},
    type: actionTypes.PRIVACY_SUBSCRIPTION_FAIL,
  }),
  privacySubscriptionRequest: (): IPrivacySubscriptionRequestAction => ({
    type: actionTypes.PRIVACY_SUBSCRIPTION_REQUEST,
  }),
  privacySubscriptionSuccess: (): IPrivacySubscriptionSuccessAction => ({
    type: actionTypes.PRIVACY_SUBSCRIPTION_SUCCESS,
  }),

  uploadId:
    (values: IUploadIdFormData) =>
    (dispatch: Dispatch<actions | CapsAction>) => {
      dispatch(actions.uploadIdRequest());
      return userService
        .uploadId(values)
        .then((user) => {
          batch(() => {
            dispatch(actions.updateUserLocalStorage({user: user as any}));
            dispatch(actions.uploadIdSuccess());
          });
        })
        .catch((error) => {
          dispatch(actions.uploadIdFail(error));
        });
    },
  uploadIdFail: (error: IApiError): IUploadIdFailAction => ({
    payload: {error},
    type: actionTypes.UPLOAD_ID_FAIL,
  }),
  uploadIdRequest: (): IUploadIdRequestAction => ({
    type: actionTypes.UPLOAD_ID_REQUEST,
  }),
  uploadIdSuccess: (): IUploadIdSuccessAction => {
    return {
      type: actionTypes.UPLOAD_ID_SUCCESS,
    };
  },

  lostPasswordWithToken:
    (values: IActivateWithTokenFormData) => (dispatch: Dispatch<actions>) => {
      dispatch(actions.lostPasswordWithTokenRequest());
      return userService
        .lostPasswordWithToken(values)
        .then(() => {
          dispatch(actions.lostPasswordWithTokenSuccess());
        })
        .catch((error) => {
          if (error.code === 910 || error.code === 913) {
            throw new SubmissionError({
              token: error.message,
            });
          }
          console.error(error); // tslint:disable-line
          dispatch(actions.lostPasswordWithTokenFail(error));
        });
    },
  lostPasswordWithTokenFail: (
    error: IApiError
  ): ILostPasswordWithTokenFailAction => ({
    payload: error,
    type: actionTypes.LOST_PASSWORD_WITH_TOKEN_FAIL,
  }),
  lostPasswordWithTokenRequest: (): ILostPasswordWithTokenRequestAction => ({
    type: actionTypes.LOST_PASSWORD_WITH_TOKEN_REQUEST,
  }),
  lostPasswordWithTokenSuccess: (): ILostPasswordWithTokenSuccessAction => ({
    type: actionTypes.LOST_PASSWORD_WITH_TOKEN_SUCCESS,
  }),

  lostPassword:
    (values: ILostPasswordFormData) => (dispatch: Dispatch<actions>) => {
      dispatch(actions.lostPasswordRequest());
      return userService
        .lostPassword(values)
        .then((responseJson) => {
          dispatch(actions.lostPasswordSuccess(responseJson.email));
        })
        .catch((error) => {
          console.error(error); // tslint:disable-line
          dispatch(actions.lostPasswordFail(error));
        });
    },
  lostPasswordFail: (error: IApiError): ILostPasswordFailAction => ({
    payload: error,
    type: actionTypes.LOST_PASSWORD_FAIL,
  }),
  lostPasswordRequest: (): ILostPasswordRequestAction => ({
    type: actionTypes.LOST_PASSWORD_REQUEST,
  }),
  lostPasswordSuccess: (email: string): ILostPasswordSuccessAction => ({
    payload: email,
    type: actionTypes.LOST_PASSWORD_SUCCESS,
  }),

  toggleAppAlert: (): IToggleAppAlertAction => ({
    type: actionTypes.TOGGLE_APP_ALERT,
  }),
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type actions =
  | IPingFailAction
  | IPingRequestAction
  | IPingSuccessAction
  | IUpdateUserLocalstorageAction
  | IProfileRequestAction
  | IProfileSuccessAction
  | IProfileFailAction
  | IPrivacyRequestAction
  | IPrivacyOpenModalAction
  | IPrivacySuccessAction
  | IPrivacyFailAction
  | IPrivacySubscriptionFailAction
  | IPrivacySubscriptionRequestAction
  | IPrivacySubscriptionSuccessAction
  | IUploadIdRequestAction
  | IUploadIdSuccessAction
  | IUploadIdFailAction
  | ILoginRequestAction
  | ILoginSuccessAction
  | ILoginFailAction
  | ILogoutRequestAction
  | ILogoutSuccessAction
  | ILogoutFailAction
  | ILoadPrivacyRequestAction
  | ILoadPrivacySuccessAction
  | ILoadPrivacyFailAction
  | IActivateRequestAction
  | IActivateSuccessAction
  | IActivateFailAction
  | IActivateWithTokenRequestAction
  | IActivateWithTokenSuccessAction
  | IActivateWithTokenFailAction
  | ILostPasswordWithTokenFailAction
  | ILostPasswordWithTokenRequestAction
  | ILostPasswordWithTokenSuccessAction
  | ILostPasswordRequestAction
  | ILostPasswordSuccessAction
  | ILostPasswordFailAction
  | IToggleAppAlertAction;
