import {lazy} from "react";
import IRoutes from "../types/IRoutes";

const ListPage = lazy(
  () => import(/* webpackChunkName: 'bene-marketing-list'  */ "./List")
);
const NewPage = lazy(
  () => import(/* webpackChunkName: 'bene-marketing-new' */ "./New")
);
const ViewPage = lazy(
  () => import(/* webpackChunkName: 'bene-marketing-view' */ "./View")
);

const routes: IRoutes = [
  {
    component: ListPage,
    exact: true,
    name: "bene-marketing-list",
    path: "/bene-marketing",
    permission: "bene.marketing.read",
    // company: "101",
    // Ulteriore controllo nel componente
  },
  {
    component: NewPage,
    exact: true,
    name: "bene-marketing-new",
    path: "/bene-marketing/new",
    permission: "bene.marketing.manage",
    company: "101",
  },
  {
    component: ViewPage,
    exact: true,
    name: "bene-marketing-view",
    path: "/bene-marketing/:id?",
    permission: "bene.marketing.read",
  },
];

export default routes;
