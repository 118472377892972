import {lazy} from "@loadable/component";
import IRoutes from "../types/IRoutes";

const AddPage = lazy(
  () => import(/* webpackChunkName: 'user-new' */ "./AddUserPage")
);
const CreateBulkPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'users-create-bulk' */ "./bulks/CreatePageContainer"
    )
);
const DeleteBulkPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'users-delete-bulk' */ "./bulks/DeletePageContainer"
    )
);
const UpdateBulkPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'users-update-bulk' */ "./bulks/UpdatePageContainer"
    )
);
const DetailPage = lazy(
  () => import(/* webpackChunkName: 'users-edit' */ "./DetailPage")
);
const ListPage = lazy(
  () => import(/* webpackChunkName: 'users' */ "./ListPage")
);
const UploadCourses = lazy(
  () =>
    import(
      /* webpackChunkName: 'users-upload-courses' */ "./bulks/UploadCourses"
    )
);

const routes: IRoutes = [
  {
    component: AddPage,
    name: "user-new",
    path: "/user/new",
    permission: "users.manage",
  },
  {
    component: ListPage,
    exact: true,
    name: "users",
    path: "/users",
    permission: "users.manage",
  },
  {
    component: DetailPage,
    name: "users-edit",
    path: "/users/edit/:userId?",
    permission: "users.manage",
  },
  {
    component: CreateBulkPage,
    name: "users-create-bulk",
    path: "/users/create-bulk",
    permission: "users.manage",
  },
  {
    component: DeleteBulkPage,
    name: "users-delete-bulk",
    path: "/users/delete-bulk",
    permission: "users.manage",
  },
  {
    component: UpdateBulkPage,
    name: "users-update-bulk",
    path: "/users/update-bulk",
    permission: "users.manage",
  },
  {
    component: UploadCourses,
    name: "users-upload-courses",
    path: "/users/upload-courses",
    permission: "users.manage",
  },
];

export default routes;
