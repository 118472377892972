import React, {FC, Suspense} from "react";
import {useSelector} from "react-redux";
import {Redirect, Route, Switch} from "react-router-dom";
import beneMarketingsRoutes from "../BeneMarketings/routes";
import beneProposalsRoutes from "../BeneProposals/routes";
import capsRoutes from "../Caps/routes";
import elementaryClassesRoutes from "../ElementaryClasses/routes";
import makeRoutes from "../helpers/makeRoutes";
import proposalsRoutes from "../Proposals/routes";
import recommendationsRoutes from "../Recommendation/routes";
import analyticsRoutes from "../Analytics/routes";
import userRoutes from "../User/routes";
import {
  getUserCompanies,
  getUserPermissions,
  selectLoggedUser,
} from "../User/selectors";
import usersRoutes from "../Users/routes";
import appRoutes from "./appRoutes";
import {Layout} from "./Layout";
import MaintenancePage from "./MaintenancePage";
import {NoMenuLayout} from "./NoMenuLayout";
import NotFoundPage from "./NotFoundPage";
import {PageLoading} from "./PageLoading";
import flatMap from "lodash/flatMap";

export const routes = [
  ...analyticsRoutes,
  ...appRoutes,
  ...beneMarketingsRoutes,
  ...beneProposalsRoutes,
  ...capsRoutes,
  ...elementaryClassesRoutes,
  ...proposalsRoutes,
  ...recommendationsRoutes,
  ...userRoutes,
  ...usersRoutes,
];

const RouterComponent: FC = () => {
  const user = useSelector(selectLoggedUser);
  const companies = useSelector(getUserCompanies);
  const permissions = useSelector(getUserPermissions);

  const maintenanceMode = false; //moment().isAfter(moment("2021-02-23 22:00:00"));
  if (maintenanceMode) {
    return <MaintenancePage />;
  }

  const isLogged = !!user;

  if (!permissions.includes) {
    console.log(
      "Errore include",
      JSON.stringify(permissions, null, 2),
      permissions
    );
  }

  const homeComponent = () => {
    if (!isLogged) {
      return <Redirect to="/login" />;
    } else if (
      permissions.includes("cap.read") ||
      permissions.includes("cap.contractor-read")
    ) {
      return <Redirect to="/analisi" />;
    } else if (
      permissions.includes("recommendation.read") ||
      permissions.includes("recommendations.read") ||
      permissions.includes("recommendation.contractor-read")
    ) {
      return <Redirect to="/raccomandazioni" />;
    } else if (permissions.includes("users.manage")) {
      return <Redirect to="/users" />;
    } else if (permissions.includes("bene.marketing.manage")) {
      return <Redirect to="/bene-marketing" />;
    } else {
      return <Redirect to="/user/profile" />;
    }
  };

  const noMenuLayoutRoutes = routes.filter(
    (route) => route.layout === "noMenu"
  );
  const defaultLayoutRoutes = routes.filter(
    (route) => route.layout === undefined || route.layout === "default"
  );

  return (
    <Switch>
      <Route exact path="/" component={homeComponent} />
      <Route exact path={flatMap(noMenuLayoutRoutes, (route) => route.path)}>
        <NoMenuLayout>
          <Suspense fallback={<PageLoading />}>
            <Switch>
              {makeRoutes({
                routes: noMenuLayoutRoutes,
                companies,
                permissions,
                isLogged,
              })}
            </Switch>
          </Suspense>
        </NoMenuLayout>
      </Route>
      <Route exact path={flatMap(defaultLayoutRoutes, (route) => route.path)}>
        <Layout>
          <Suspense fallback={<PageLoading />}>
            <Switch>
              {makeRoutes({
                routes: defaultLayoutRoutes,
                companies,
                permissions,
                isLogged,
              })}
            </Switch>
          </Suspense>
        </Layout>
      </Route>
      <Layout>
        <Route component={NotFoundPage} />
      </Layout>
    </Switch>
  );
};

export default RouterComponent;
