import {
  Actions as ESignActions,
  ActionTypes as ESignActionTypes,
} from "../ESign/actions";
import {IAppThunkActionStates} from "../types/thunk";
import {
  actions as userActions,
  actionTypes as userActionTypes,
} from "../User/actions";
import {Actions, ActionTypes} from "./actions";
import {
  Actions as CompaniesActions,
  ActionTypes as CompaniesActionTypes,
} from "./actions/companies";
import {
  Actions as OptionsActions,
  ActionTypes as OptionsActionTypes,
} from "./actions/options";
import {
  ICapParams,
  ICapsEntities,
  ICity,
  ICopyData,
  IInvestmentCompany,
  IInvestmentOption,
  NewCapModalTypes,
} from "./types";

export type ThunkActionsNames = "loadCopy" | "copyDataInCap";

export interface IStateUi
  extends Readonly<{
    isDeletePending?: {[key: string]: boolean};
    isDeleteSuccess?: boolean;
    lastDeleted?: string;

    errorUndoDelete?: string;
    isUndoDeletePending?: boolean;
    isUndoDeleteSuccess?: boolean;
    isUndoDeleteFail?: boolean;

    createContractorError?: string;
    isCreateContractorPending?: boolean;
    isCreateContractorSuccess?: boolean;
    isCreateContractorFail?: boolean;
    isCreateContractorAnotherAdvisor?: boolean;

    updateContractorError?: string;
    isUpdateContractorPending?: boolean;
    isUpdateContractorSuccess?: boolean;
    isUpdateContractorFail?: boolean;

    useExistingContractorError?: string;
    isUseExistingContractorPending?: boolean;
    isUseExistingContractorSuccess?: boolean;
    isUseExistingContractorFail?: boolean;

    eSignError?: string;
    isESignPending?: boolean;
    isESignSuccess?: boolean;
    isESignFail?: boolean;

    completeContractorError?: string;
    isCompleteContractorPending?: boolean;
    isCompleteContractorSuccess?: boolean;
    isCompleteContractorFail?: boolean;
    isCompleteContractorResetButton?: boolean;

    isRemoveContractorPending?: {[key: number]: boolean};
    editingContractor?: number;

    uploadIdError?: string;
    isUploadIdPending?: boolean;
    isUploadIdSuccess?: boolean;
    isUploadIdFail?: boolean;

    identificationError?: string;
    isIdentificationFail?: boolean;
    isIdentificationPending?: boolean;
    isIdentificationSuccess?: boolean;

    homeError?: string;
    isHomeFail?: boolean;
    isHomePending?: boolean;
    isHomeSuccess?: boolean;

    employmentError?: string;
    isEmploymentFail?: boolean;
    isEmploymentPending?: boolean;
    isEmploymentSuccess?: boolean;

    incomesError?: string;
    isIncomesFail?: boolean;
    isIncomesPending?: boolean;
    isIncomesSuccess?: boolean;

    targetError?: string;
    isTargetFail?: boolean;
    isTargetPending?: boolean;
    isTargetSuccess?: boolean;

    savingError?: string;
    isSavingFail?: boolean;
    isSavingPending?: boolean;
    isSavingSuccess?: boolean;

    overdraftError?: string;
    isOverdraftFail?: boolean;
    isOverdraftPending?: boolean;
    isOverdraftSuccess?: boolean;

    hasIBIPsError?: string;
    isHasIBIPsFail?: boolean;
    isHasIBIPsPending?: boolean;
    isHasIBIPsSuccess?: boolean;

    iddingContractor?: number;
    iddError?: string;
    isIddFail?: boolean;
    isIddPending?: boolean;
    isIddSuccess?: boolean;

    viewingIddContractor?: number;

    isModalOpen: {[key in NewCapModalTypes]?: boolean};
    isModalOpenId?: string;

    readError?: string;
    readRedirect?: boolean;
    isReadPending?: boolean;
    isReadSuccess?: boolean;
    isReadFail?: boolean;

    closeOverdraftsError?: string;
    isCloseOverdraftsFail?: boolean;
    isCloseOverdraftsPending?: boolean;
    isCloseOverdraftsSuccess?: boolean;

    requestValidationError?: string;
    isRequestValidationFail?: boolean;
    isRequestValidationPending?: boolean;
    isRequestValidationSuccess?: boolean;

    closeValidationError?: string;
    isCloseValidationFail?: boolean;
    isCloseValidationPending?: boolean;
    isCloseValidationSuccess?: boolean;

    viewFromDbError?: string;
    viewFromDbRedirect?: boolean;
    isViewFromDbPending?: boolean;
    isViewFromDbSuccess?: boolean;
    isViewFromDbFail?: boolean;

    deliveryError?: string;
    isDeliveryFail?: boolean;
    isDeliveryPending?: boolean;
    isDeliverySuccess?: boolean;

    identificationNotificationError?: string;
    identificationNotificationFail?: boolean;
    identificationNotificationPending?: boolean;
    identificationNotificationSuccess?: boolean;

    optionsListError?: string;
    isOptionsListFail?: boolean;
    isOptionsListPending?: boolean;
    isOptionsListSuccess?: boolean;

    loadCompaniesError?: string;
    isLoadCompaniesFail?: boolean;
    isLoadCompaniesPending?: boolean;
    isLoadCompaniesSuccess?: boolean;

    citiesListError?: string;
    isCitiesListFail?: boolean;
    isCitiesListPending?: boolean;
    isCitiesListSuccess?: boolean;
  }> {}

export interface IStateList
  extends Readonly<{
    isPending?: boolean;
    isSuccess?: boolean;
    isFail?: boolean;
    error?: string;
    items: ReadonlyArray<string>;
    numItems?: number;
    params: ICapParams;
  }> {}

export interface IStateNewCap
  extends Readonly<{
    addContractorModalIsOpen: boolean;
    isPending?: boolean;
    isSuccess?: boolean;
    isFail?: boolean;
    error?: string;
    lastCreatedId?: string;
    password?: string;
    errorExistingUserId?: string;
    thunkActionsStates: {[key in ThunkActionsNames]: IAppThunkActionStates};
    copyData?: ICopyData;
    chosenCap?: string;
  }> {}

export interface IState
  extends Readonly<{
    entities: ICapsEntities;
    list: IStateList;
    newCap: IStateNewCap;
    options?: IInvestmentOption[];
    companies?: IInvestmentCompany[];
    cities?: ICity[];
    ui: IStateUi;
  }> {}

const capsInitialState = {
  entities: {},
  list: {items: [], params: {limit: 50, offset: 0}},
  newCap: {
    addContractorModalIsOpen: false,
    thunkActionsStates: {loadCopy: {}, copyDataInCap: {}},
  },
  ui: {isIddSendModalOpen: {}, isModalOpen: {}},
};

// const testReducer = (
//   state: IState = capsInitialState,
//   action:
//     | Actions
//     | OptionsActions
//     | CompaniesActions
//     | ESignActions
//     | userActions
// ): IState => {
//   return state;
// };

export const reducer = (
  state: IState = capsInitialState,
  action:
    | Actions
    | OptionsActions
    | CompaniesActions
    | ESignActions
    | userActions
): IState => {
  switch (action.type) {
    case userActionTypes.LOGOUT_SUCCESS:
      return {
        ...capsInitialState,
      };
    case ActionTypes.MERGE_ITEMS:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.caps,
        },
      };

    case ActionTypes.OPEN_NEW_CAP_MODAL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isModalOpen: {
            ...state.ui.isModalOpen,
            [action.payload.modalType]: true,
          },
          isModalOpenId: action.payload.id,
        },
      };
    case ActionTypes.CLOSE_NEW_CAP_MODAL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isModalOpen: {
            ...state.ui.isModalOpen,
            [action.payload.modalType]: false,
          },
        },
      };

    case ActionTypes.CHOOSE_CAP:
      return {
        ...state,
        newCap: {
          ...state.newCap,
          chosenCap: action.payload.capId,
        },
      };

    case ESignActionTypes.SIGN_FEA_DOC_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isModalOpen: {
            ...state.ui.isModalOpen,
            [NewCapModalTypes.PrivacySubscription]: false,
          },
        },
      };

    case ActionTypes.LIST_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          error: undefined,
          isFail: false,
          isPending: true,
          isSuccess: false,
        },
      };
    case ActionTypes.LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isPending: false,
          isSuccess: true,
          items: action.payload.capIds,
          numItems: action.payload.numCaps,
          params: action.payload.params,
        },
        ui: {
          ...state.ui,
          isDeleteSuccess: false,
        },
      };
    case ActionTypes.LIST_FAIL:
      return {
        ...state,
        list: {
          ...state.list,
          error:
            action.payload && action.payload.message
              ? action.payload.message
              : "Errore sconosciuto, riprova più tardi",
          isFail: true,
          isPending: false,
        },
      };
    case ActionTypes.CREATE_REQUEST:
      return {
        ...state,
        newCap: {
          ...state.newCap,
          error: undefined,
          isFail: false,
          isPending: true,
          isSuccess: false,
        },
      };
    case ActionTypes.CREATE_SUCCESS:
      return {
        ...state,
        newCap: {
          ...state.newCap,
          lastCreatedId: action.payload.capId,
          error: undefined,
          isFail: false,
          isPending: false,
          isSuccess: true,
        },
      };
    case ActionTypes.CREATE_FAIL:
      return {
        ...state,
        newCap: {
          ...state.newCap,
          error:
            action.payload && action.payload.message
              ? action.payload.message
              : "Errore sconosciuto, riprova più tardi",
          isFail: true,
          isPending: false,
          isSuccess: false,
        },
      };

    case ActionTypes.DELETE_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDeletePending: {
            ...state.ui.isDeletePending,
            [action.payload.id]: true,
          },
          isDeleteSuccess: false,
        },
      };
    case ActionTypes.DELETE_SUCCESS:
      const capId = action.payload.id;
      const deleteIndex = state.list.items.indexOf(capId);
      return {
        ...state,
        list: {
          ...state.list,
          items: [
            ...state.list.items.slice(0, deleteIndex),
            ...state.list.items.slice(deleteIndex + 1),
          ],
        },
        ui: {
          ...state.ui,
          isDeletePending: {
            ...state.ui.isDeletePending,
            [action.payload.id]: false,
          },
          isDeleteSuccess: true,
          lastDeleted: capId,
        },
      };
    case ActionTypes.DELETE_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDeletePending: {
            ...state.ui.isDeletePending,
            [action.payload.id]: false,
          },
          isDeleteSuccess: false,
        },
      };
    case ActionTypes.RESET_DELETE_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isDeleteSuccess: false,
        },
      };

    case ActionTypes.UNDO_DELETE_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          errorUndoDelete: undefined,
          isUndoDeleteFail: false,
          isUndoDeletePending: true,
          isUndoDeleteSuccess: false,
        },
      };
    case ActionTypes.UNDO_DELETE_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          errorUndoDelete: undefined,
          isUndoDeleteFail: false,
          isUndoDeletePending: false,
          isUndoDeleteSuccess: true,
        },
      };
    case ActionTypes.UNDO_DELETE_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          errorUndoDelete: action.payload.error.message,
          isUndoDeleteFail: true,
          isUndoDeletePending: false,
          isUndoDeleteSuccess: false,
        },
      };

    case ActionTypes.CREATE_CONTRACTOR_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          createContractorError: undefined,
          isCreateContractorAnotherAdvisor: false,
          isCreateContractorFail: false,
          isCreateContractorPending: true,
          isCreateContractorSuccess: false,
        },
      };
    case ActionTypes.CREATE_CONTRACTOR_SUCCESS:
      return {
        ...state,
        newCap: {
          ...state.newCap,
        },
        ui: {
          ...state.ui,
          createContractorError: undefined,
          isCreateContractorAnotherAdvisor: false,
          isCreateContractorFail: false,
          isCreateContractorPending: false,
          isCreateContractorSuccess: true,
          isModalOpen: {
            ...state.ui.isModalOpen,
            [NewCapModalTypes.CreateContractor]: false,
          },
        },
      };
    case ActionTypes.CREATE_CONTRACTOR_RESET:
      return {
        ...state,
        newCap: {
          ...state.newCap,
          errorExistingUserId: undefined,
        },
        ui: {
          ...state.ui,
          createContractorError: undefined,
          isCreateContractorAnotherAdvisor: false,
          isCreateContractorFail: false,
          isCreateContractorPending: false,
          isCreateContractorSuccess: false,
        },
      };
    case ActionTypes.CREATE_CONTRACTOR_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          createContractorError: action.payload && action.payload.message,
          isCreateContractorFail: true,
          isCreateContractorPending: false,
          isCreateContractorSuccess: false,
        },
      };
    case ActionTypes.CREATE_CONTRACTOR_ANOTHER_ADVISOR:
      return {
        ...state,
        ui: {
          ...state.ui,
          isCreateContractorAnotherAdvisor: true,
        },
      };
    case ActionTypes.USE_EXISTING_CONTRACTOR_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isUseExistingContractorFail: false,
          isUseExistingContractorPending: true,
          isUseExistingContractorSuccess: false,
          useExistingContractorError: undefined,
        },
      };
    case ActionTypes.USE_EXISTING_CONTRACTOR_SUCCESS:
      return {
        ...state,
        newCap: {
          ...state.newCap,
        },
        ui: {
          ...state.ui,
          isModalOpen: {
            ...state.ui.isModalOpen,
            [NewCapModalTypes.CreateContractor]: false,
          },
          isUseExistingContractorFail: false,
          isUseExistingContractorPending: false,
          isUseExistingContractorSuccess: true,
          useExistingContractorError: undefined,
        },
      };
    case ActionTypes.USE_EXISTING_CONTRACTOR_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isUseExistingContractorFail: true,
          isUseExistingContractorPending: false,
          isUseExistingContractorSuccess: false,
          useExistingContractorError: action.payload.message,
        },
      };
    case ActionTypes.SAVE_CONTRACTOR_ID_CREATE_ERROR:
      return {
        ...state,
        newCap: {
          ...state.newCap,
          errorExistingUserId: action.payload.contractorId,
        },
      };
    case ActionTypes.RESET_CREATE_USE_EXISTING_CONTRACTOR:
      return {
        ...state,
        ui: {
          ...state.ui,
          createContractorError: undefined,
          isCreateContractorAnotherAdvisor: false,
          isCreateContractorFail: false,
          isCreateContractorPending: false,
          isCreateContractorSuccess: false,
          isModalOpen: {
            ...state.ui.isModalOpen,
            [NewCapModalTypes.CreateContractor]: false,
            [NewCapModalTypes.UpdateContractor]: false,
          },
          isUpdateContractorFail: false,
          isUpdateContractorPending: false,
          isUpdateContractorSuccess: false,
          isUseExistingContractorFail: false,
          isUseExistingContractorPending: false,
          isUseExistingContractorSuccess: false,
          updateContractorError: undefined,
          useExistingContractorError: undefined,
        },
      };
    case ActionTypes.RESET_LAST_CREATED_ID:
      return {
        ...state,
        newCap: {
          ...state.newCap,
          lastCreatedId: undefined,
        },
      };

    case ActionTypes.COMPLETE_CONTRACTOR_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isCompleteContractorFail: false,
          isCompleteContractorPending: true,
          isCompleteContractorSuccess: false,
        },
      };
    case ActionTypes.COMPLETE_CONTRACTOR_SUCCESS:
      return {
        ...state,
        newCap: {
          ...state.newCap,
          addContractorModalIsOpen: false,
        },
        ui: {
          ...state.ui,
          isCompleteContractorFail: false,
          isCompleteContractorPending: false,
          isCompleteContractorSuccess: true,
          isModalOpen: {
            ...state.ui.isModalOpen,
            [NewCapModalTypes.CompleteContractor]: false,
            [NewCapModalTypes.UpdateContractor]: false,
          },
        },
      };
    case ActionTypes.COMPLETE_CONTRACTOR_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          completeContractorError: action.payload && action.payload.message,
          isCompleteContractorFail: true,
          isCompleteContractorPending: false,
          isCompleteContractorSuccess: false,
        },
      };
    case ActionTypes.READ_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isReadFail: false,
          isReadPending: true,
          isReadSuccess: false,
          readRedirect: false,
        },
      };
    case ActionTypes.READ_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isReadFail: false,
          isReadPending: false,
          isReadSuccess: true,
          readRedirect: false,
        },
      };
    case ActionTypes.READ_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isReadFail: true,
          isReadPending: false,
          isReadSuccess: false,
          readError: action.payload.message,
          readRedirect: action.payload.code === 301,
        },
      };
    case ActionTypes.RESET_READ_REDIRECT:
      return {
        ...state,
        ui: {
          ...state.ui,
          readRedirect: false,
        },
      };
    case ActionTypes.READ_RESET_UI_ERROR:
      return {
        ...state,
        ui: {
          ...state.ui,
          isReadFail: false,
          readRedirect: false,
        },
      };

    case ActionTypes.UPLOAD_ID_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isUploadIdFail: true,
          isUploadIdPending: false,
          isUploadIdSuccess: false,
          uploadIdError:
            action.payload && action.payload.error.message
              ? action.payload.error.message
              : "Errore sconosciuto, riprova più tardi",
        },
      };
    case ActionTypes.UPLOAD_ID_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isUploadIdFail: false,
          isUploadIdPending: true,
          isUploadIdSuccess: false,
          uploadIdError: undefined,
        },
      };
    case ActionTypes.UPLOAD_ID_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isModalOpen: {
            ...state.ui.isModalOpen,
            [NewCapModalTypes.UploadId]: false,
          },
          isUploadIdFail: false,
          isUploadIdPending: false,
          isUploadIdSuccess: true,
        },
      };

    case userActionTypes.UPLOAD_ID_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isModalOpen: {
            ...state.ui.isModalOpen,
            [NewCapModalTypes.UploadId]: false,
          },
        },
      };

    case ActionTypes.CONFIRM_IDENTIFICATION_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          identificationError: undefined,
          isIdentificationFail: false,
          isIdentificationPending: true,
          isIdentificationSuccess: false,
        },
      };
    case ActionTypes.CONFIRM_IDENTIFICATION_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          identificationError: undefined,
          isIdentificationFail: false,
          isIdentificationPending: false,
          isIdentificationSuccess: true,
        },
      };

    case ActionTypes.CONFIRM_IDENTIFICATION_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          identificationError: action.payload.error.message,
          isIdentificationFail: true,
          isIdentificationPending: false,
          isIdentificationSuccess: false,
        },
      };

    case ActionTypes.HOME_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          homeError: undefined,
          isHomeFail: false,
          isHomePending: true,
          isHomeSuccess: false,
        },
      };
    case ActionTypes.HOME_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          homeError: undefined,
          isHomeFail: false,
          isHomePending: false,
          isHomeSuccess: true,
          isModalOpen: {
            ...state.ui.isModalOpen,
            [NewCapModalTypes.Home]: false,
          },
        },
      };
    case ActionTypes.HOME_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          homeError: action.payload.error.message,
          isHomeFail: true,
          isHomePending: false,
          isHomeSuccess: false,
        },
      };

    case ActionTypes.EMPLOYMENT_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          employmentError: undefined,
          isEmploymentFail: false,
          isEmploymentPending: true,
          isEmploymentSuccess: false,
        },
      };
    case ActionTypes.EMPLOYMENT_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          employmentError: undefined,
          isEmploymentFail: false,
          isEmploymentPending: false,
          isEmploymentSuccess: true,
          isModalOpen: {
            ...state.ui.isModalOpen,
            [NewCapModalTypes.Employment]: false,
          },
        },
      };
    case ActionTypes.EMPLOYMENT_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          employmentError: action.payload.error.message,
          isEmploymentFail: true,
          isEmploymentPending: false,
          isEmploymentSuccess: false,
        },
      };

    case ActionTypes.INCOMES_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          incomesError: undefined,
          isIncomesFail: false,
          isIncomesPending: true,
          isIncomesSuccess: false,
        },
      };
    case ActionTypes.INCOMES_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          incomesError: undefined,
          isIncomesFail: false,
          isIncomesPending: false,
          isIncomesSuccess: true,
          isModalOpen: {
            ...state.ui.isModalOpen,
            [NewCapModalTypes.Incomes]: false,
          },
        },
      };
    case ActionTypes.INCOMES_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          incomesError: action.payload.error.message,
          isIncomesFail: true,
          isIncomesPending: false,
          isIncomesSuccess: false,
        },
      };

    case ActionTypes.TARGET_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isTargetFail: false,
          isTargetPending: true,
          isTargetSuccess: false,
          targetError: undefined,
        },
      };
    case ActionTypes.TARGET_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isModalOpen: {
            ...state.ui.isModalOpen,
            [NewCapModalTypes.Target]: false,
          },
          isTargetFail: false,
          isTargetPending: false,
          isTargetSuccess: true,
          targetError: undefined,
        },
      };
    case ActionTypes.TARGET_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isTargetFail: true,
          isTargetPending: false,
          isTargetSuccess: false,
          targetError: action.payload.error.message,
        },
      };

    case ActionTypes.SAVING_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isSavingFail: false,
          isSavingPending: true,
          isSavingSuccess: false,
          savingError: undefined,
        },
      };
    case ActionTypes.SAVING_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isModalOpen: {
            ...state.ui.isModalOpen,
            [NewCapModalTypes.Saving]: false,
          },
          isSavingFail: false,
          isSavingPending: false,
          isSavingSuccess: true,
          savingError: undefined,
        },
      };
    case ActionTypes.SAVING_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isSavingFail: true,
          isSavingPending: false,
          isSavingSuccess: false,
          savingError: action.payload.error.message,
        },
      };

    case ActionTypes.OVERDRAFT_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isOverdraftFail: false,
          isOverdraftPending: true,
          isOverdraftSuccess: false,
          overdraftError: undefined,
        },
      };
    case ActionTypes.OVERDRAFT_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isOverdraftFail: false,
          isOverdraftPending: false,
          isOverdraftSuccess: true,
          overdraftError: undefined,
          isModalOpen: {
            ...state.ui.isModalOpen,
            [NewCapModalTypes.OverDrafts]: false,
          },
        },
      };
    case ActionTypes.OVERDRAFT_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isOverdraftFail: true,
          isOverdraftPending: false,
          isOverdraftSuccess: false,
          overdraftError: action.payload.error.message,
        },
      };

    case ActionTypes.HAS_IBIPS_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          hasIBIPsError: undefined,
          isHasIBIPsFail: false,
          isHasIBIPsPending: true,
          isHasIBIPsSuccess: false,
        },
      };
    case ActionTypes.HAS_IBIPS_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          hasIBIPsError: undefined,
          isHasIBIPsFail: false,
          isHasIBIPsPending: false,
          isHasIBIPsSuccess: true,
        },
      };
    case ActionTypes.HAS_IBIPS_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          hasIBIPsError: action.payload.error.message,
          isHasIBIPsFail: true,
          isHasIBIPsPending: false,
          isHasIBIPsSuccess: false,
        },
      };

    case ActionTypes.IDD_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          iddError: undefined,
          isIddFail: false,
          isIddPending: true,
          isIddSuccess: false,
        },
      };
    case ActionTypes.IDD_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          iddError: undefined,
          isIddFail: false,
          isIddPending: false,
          isIddSuccess: true,
          isModalOpen: {
            ...state.ui.isModalOpen,
            [NewCapModalTypes.Idd]: false,
          },
        },
      };
    case ActionTypes.IDD_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          iddError: action.payload.error.message,
          isIddFail: true,
          isIddPending: false,
          isIddSuccess: false,
        },
      };
    case ActionTypes.IDD_FROM_VIEW:
      return {
        ...state,
        ui: {
          ...state.ui,
          isModalOpen: {
            ...state.ui.isModalOpen,
            [NewCapModalTypes.Idd]: false,
          },
        },
      };

    case ActionTypes.CLOSE_OVERDRAFTS_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          closeOverdraftsError: action.payload.error.message,
          isCloseOverdraftsFail: true,
          isCloseOverdraftsPending: false,
          isCloseOverdraftsSuccess: false,
        },
      };
    case ActionTypes.CLOSE_OVERDRAFTS_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isCloseOverdraftsFail: false,
          isCloseOverdraftsPending: true,
          isCloseOverdraftsSuccess: false,
        },
      };
    case ActionTypes.CLOSE_OVERDRAFTS_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isCloseOverdraftsFail: false,
          isCloseOverdraftsPending: false,
          isCloseOverdraftsSuccess: true,
        },
      };

    case ActionTypes.REQUEST_VALIDATION_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isRequestValidationFail: true,
          isRequestValidationPending: false,
          isRequestValidationSuccess: false,
          requestValidationError: action.payload.error.message,
        },
      };
    case ActionTypes.REQUEST_VALIDATION_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isRequestValidationFail: false,
          isRequestValidationPending: true,
          isRequestValidationSuccess: false,
        },
      };
    case ActionTypes.REQUEST_VALIDATION_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isRequestValidationFail: false,
          isRequestValidationPending: false,
          isRequestValidationSuccess: true,
        },
      };
    case ActionTypes.CLOSE_VALIDATION_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          closeValidationError: action.payload.error.message,
          isCloseValidationFail: true,
          isCloseValidationPending: false,
          isCloseValidationSuccess: false,
        },
      };
    case ActionTypes.CLOSE_VALIDATION_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isCloseValidationFail: false,
          isCloseValidationPending: true,
          isCloseValidationSuccess: false,
        },
      };
    case ActionTypes.CLOSE_VALIDATION_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isCloseValidationFail: false,
          isCloseValidationPending: false,
          isCloseValidationSuccess: true,
        },
      };

    case ActionTypes.DELIVERY_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          deliveryError: undefined,
          isDeliveryFail: false,
          isDeliveryPending: true,
          isDeliverySuccess: false,
        },
      };
    case ActionTypes.DELIVERY_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          deliveryError: undefined,
          isDeliveryFail: false,
          isDeliveryPending: false,
          isDeliverySuccess: true,
          isModalOpen: {
            ...state.ui.isModalOpen,
            [NewCapModalTypes.Delivery]: false,
          },
        },
      };
    case ActionTypes.DELIVERY_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          deliveryError: action.payload.error.message,
          isDeliveryFail: true,
          isDeliveryPending: false,
          isDeliverySuccess: false,
        },
      };

    case ActionTypes.IDENTIFICATION_NOTIFICATION_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          identificationError: undefined,
          isIdentificationFail: false,
          isIdentificationPending: true,
          isIdentificationSuccess: false,
        },
      };
    case ActionTypes.IDENTIFICATION_NOTIFICATION_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          identificationError: undefined,
          isIdentificationFail: false,
          isIdentificationPending: false,
          isIdentificationSuccess: true,
        },
      };
    case ActionTypes.IDENTIFICATION_NOTIFICATION_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          identificationError: action.payload.error.message,
          isIdentificationFail: true,
          isIdentificationPending: false,
          isIdentificationSuccess: false,
        },
      };

    case ActionTypes.VIEW_FROM_DB_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isViewFromDbFail: false,
          isViewFromDbPending: true,
          isViewFromDbSuccess: false,
          viewFromDbRedirect: false,
        },
      };
    case ActionTypes.VIEW_FROM_DB_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          isViewFromDbFail: false,
          isViewFromDbPending: false,
          isViewFromDbSuccess: true,
          viewFromDbRedirect: false,
        },
      };
    case ActionTypes.VIEW_FROM_DB_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isViewFromDbFail: true,
          isViewFromDbPending: false,
          isViewFromDbSuccess: false,
          viewFromDbError: action.payload.message,
          viewFromDbRedirect: action.payload.code === 301,
        },
      };
    case ActionTypes.VIEW_FROM_DB_RESET_UI_ERROR:
      return {
        ...state,
        ui: {
          ...state.ui,
          isViewFromDbFail: false,
          viewFromDbRedirect: false,
        },
      };

    case OptionsActionTypes.LOAD_OPTIONS_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isOptionsListFail: false,
          isOptionsListPending: true,
          isOptionsListSuccess: false,
          optionsListError: undefined,
        },
      };
    case OptionsActionTypes.LOAD_OPTIONS_SUCCESS:
      return {
        ...state,
        options: [...action.payload.options],
        ui: {
          ...state.ui,
          isOptionsListFail: false,
          isOptionsListPending: false,
          isOptionsListSuccess: true,
          optionsListError: undefined,
        },
      };
    case OptionsActionTypes.LOAD_OPTIONS_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isOptionsListFail: true,
          isOptionsListPending: false,
          isOptionsListSuccess: false,
          optionsListError: action.payload.error.message,
        },
      };

    case CompaniesActionTypes.LOAD_COMPANIES_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadCompaniesFail: false,
          isLoadCompaniesPending: true,
          isLoadCompaniesSuccess: false,
          loadCompaniesError: undefined,
        },
      };
    case CompaniesActionTypes.LOAD_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: [...action.payload.companies],
        ui: {
          ...state.ui,
          isLoadCompaniesFail: false,
          isLoadCompaniesPending: false,
          isLoadCompaniesSuccess: true,
          loadCompaniesError: undefined,
        },
      };
    case CompaniesActionTypes.LOAD_COMPANIES_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isLoadCompaniesFail: true,
          isLoadCompaniesPending: false,
          isLoadCompaniesSuccess: false,
          loadCompaniesError: action.payload.error.message,
        },
      };

    case ActionTypes.LOAD_COPY_REQUEST:
      return {
        ...state,
        newCap: {
          ...state.newCap,
          thunkActionsStates: {
            ...state.newCap.thunkActionsStates,
            loadCopy: {
              error: undefined,
              isFail: false,
              isPending: true,
              isSuccess: false,
            },
          },
        },
      };
    case ActionTypes.LOAD_COPY_SUCCESS:
      return {
        ...state,
        newCap: {
          ...state.newCap,
          copyData: action.payload.copyData,
          thunkActionsStates: {
            ...state.newCap.thunkActionsStates,
            loadCopy: {
              error: undefined,
              isFail: false,
              isPending: false,
              isSuccess: true,
            },
          },
        },
      };
    case ActionTypes.LOAD_COPY_FAIL:
      return {
        ...state,
        newCap: {
          ...state.newCap,
          thunkActionsStates: {
            ...state.newCap.thunkActionsStates,
            loadCopy: {
              error: action.payload.error.message,
              isFail: true,
              isPending: false,
              isSuccess: false,
            },
          },
        },
      };
    case ActionTypes.LOAD_COPY_RESET:
      return {
        ...state,
        newCap: {
          ...state.newCap,
          copyData: undefined,
          thunkActionsStates: {
            ...state.newCap.thunkActionsStates,
            loadCopy: {},
          },
        },
      };

    case ActionTypes.COPY_DATA_IN_CAP_REQUEST:
      return {
        ...state,
        newCap: {
          ...state.newCap,
          thunkActionsStates: {
            ...state.newCap.thunkActionsStates,
            copyDataInCap: {
              error: undefined,
              isFail: false,
              isPending: true,
              isSuccess: false,
            },
          },
        },
      };
    case ActionTypes.COPY_DATA_IN_CAP_SUCCESS:
      return {
        ...state,
        newCap: {
          ...state.newCap,
          thunkActionsStates: {
            ...state.newCap.thunkActionsStates,
            copyDataInCap: {
              error: undefined,
              isFail: false,
              isPending: false,
              isSuccess: true,
            },
          },
        },
      };
    case ActionTypes.COPY_DATA_IN_CAP_FAIL:
      return {
        ...state,
        newCap: {
          ...state.newCap,
          thunkActionsStates: {
            ...state.newCap.thunkActionsStates,
            copyDataInCap: {
              error: action.payload.error.message,
              isFail: true,
              isPending: false,
              isSuccess: false,
            },
          },
        },
      };

    case ActionTypes.LOAD_CITIES_REQUEST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isCitiesListFail: false,
          isCitiesListPending: true,
          isCitiesListSuccess: false,
          citiesListError: undefined,
        },
      };
    case ActionTypes.LOAD_CITIES_SUCCESS:
      return {
        ...state,
        cities: [
          ...action.payload.cities,
          ...(action.meta.options.exist
            ? []
            : [
                {
                  idObject: "0",
                  cNomeComune: "ESTERO",
                  cSiglaProvincia: "EE",
                  bExist: "0",
                },
              ]),
        ],
        ui: {
          ...state.ui,
          isCitiesListFail: false,
          isCitiesListPending: false,
          isCitiesListSuccess: true,
          citiesListError: undefined,
        },
      };
    case ActionTypes.LOAD_CITIES_FAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          isCitiesListFail: true,
          isCitiesListPending: false,
          isCitiesListSuccess: false,
          citiesListError: action.payload.error.message,
        },
      };

    case ActionTypes.LIST_BENE_MARKETING_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          error: undefined,
          isFail: false,
          isPending: true,
          isSuccess: false,
        },
      };
    case ActionTypes.LIST_BENE_MARKETING_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isPending: false,
          isSuccess: true,
          items: action.payload.capIds,
          numItems: action.payload.numCaps,
        },
        ui: {
          ...state.ui,
          isDeleteSuccess: false,
        },
      };
    case ActionTypes.LIST_BENE_MARKETING_FAIL:
      return {
        ...state,
        list: {
          ...state.list,
          error:
            action.payload && action.payload.message
              ? action.payload.message
              : "Errore sconosciuto, riprova più tardi",
          isFail: true,
          isPending: false,
        },
      };

    default:
      return state;
  }
};

export default reducer;

// const combinedReducer = reduceReducers(capsInitialState, testReducer, reducer);
